/* eslint-disable array-callback-return */
import _ from 'lodash';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/reducer_utility';
import { getInitialComapnyAddressList } from './businessProfile';

export const campaignDetail = [{
    campaignId: null,
    startDate: null,
    endDate: null,
    campaignCreatedDateTime: null,
    packageId: null,
    packageName: null,
    campaignName: null,
    companyId: null,
    isIntakeComplete: null
}];

const initialIndividualLocation = {
    location: {
        id: null,
        companyId: null,
        storeLocationName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        budget: null,
        noLocationsInMarket: 0
    },
    errorMessages: {
        storeLocationNameError: '',
        address1Error: '',
        cityError: '',
        stateError: '',
        zipError: '',
        noLocationsInMarketError: ''
    }
};

const initialState = {
    companyDetails: {
        companyId: null,
        companyName: null,
        companyLegalName: null,
        adminNotes: null,
        personDetail: {
            personId: null,
            firstName: null,
            lastName: null,
            userName: null,
            contactNumber: null,
            email: null
        },
        accountsPayableName: null,
        accountsPayableEmail: null,
        taxId: null,
        additionalEmail: null,
        customerSuccessPersonId: null,
        timeZone: null,
        companyAddresses: getInitialComapnyAddressList()
    },
    userName: null,
    isLocationEditable: false,
    isCompanyEditable: false,
    isAccountSettingsEditable: false,
    businessEmail: null,
    activeCampaigns: { ...campaignDetail },
    archivedCampaigns: { ...campaignDetail },
    companyPackageDicounts: [],
    packages: [],
    loading: false,
    errorMessage: null,
    isCompanyProfileSaved: false,
    locations: [initialIndividualLocation],
    unUsedLocations: [],
    unUsedActiveCampaigns: [],
    isMovedToArchive: false,
    customerStatus: ''
};

const getAdminCustomerDetailsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        errorMessage: null
    });
};

const adminCustomerDetailsFinish = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: null
    });
};

const getAdminCustomerDetailsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errorMessage: action.errorMessage
    });
};

const updateCompanyProfileIsSaved = (state, action) => {
    return updateObject(state, {
        isCompanyProfileSaved: action.data
    });
};

const createAddress = (address) => {
    return {
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        isBillingAddress: true,
        isPrimary: true
    };
};

const createCompanyaddressObject = (campaignCompanyAddress) => {
    let tempCampaignCompanyAddress = [];
    if (campaignCompanyAddress.length === 0) {
        tempCampaignCompanyAddress = getInitialComapnyAddressList();
    } else if (campaignCompanyAddress.length === 1) {
        tempCampaignCompanyAddress.push(createAddress(campaignCompanyAddress[0]));
        tempCampaignCompanyAddress.push({
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            isBillingAddress: false,
            isPrimary: false
        });
    } else if (campaignCompanyAddress.length === 2) {
        tempCampaignCompanyAddress = campaignCompanyAddress;
    }
    return tempCampaignCompanyAddress;
};

const createIndividualLocation = (item, type) => {
    return {
        location: {
            id: item.id,
            companyId: item.companyId,
            storeLocationName: item.storeLocationName,
            address1: item.address1 || '',
            address2: item.address2 || '',
            city: item.city || '',
            state: item.state || '',
            zip: item.zip || '',
            noLocationsInMarket: item.noLocationsInMarket || 0
        },
        errorMessages: {
            storeLocationNameError: '',
            address1Error: '',
            stateError: '',
            cityError: '',
            zipError: '',
            budgetError: '',
            noLocationsInMarketError: ''
        }
    };
};

const createLocationObject = (data) => {
    console.log((initialIndividualLocation));

    const tempLocations = [];
    if (data === null) {
        tempLocations.push(initialIndividualLocation);
    } else {
        data.map((item, index) => {
            tempLocations.push(createIndividualLocation(item, 'GET'));
        });
    }
    return tempLocations;
};

const getAdminCustomerDetailsSuccess = (state, action) => {
    const { companyDetails } = action.data;
    const tempCompanyDetails = {
        companyId: companyDetails.companyId,
        companyName: companyDetails.companyName || '',
        companyLegalName: companyDetails.companyLegalName,
        additionalEmail: companyDetails.additionalEmail,
        adminNotes: companyDetails.adminNotes,
        personDetail: companyDetails.personDetail,
        accountsPayableName: companyDetails.accountsPayableName || '',
        accountsPayableEmail: companyDetails.accountsPayableEmail || '',
        taxId: companyDetails.taxId,
        customerSuccessPersonId: companyDetails.customerSuccessPersonId,
        timeZone: companyDetails.timeZone,
        companyAddresses: companyDetails.companyAddresses
            ? createCompanyaddressObject(companyDetails.companyAddresses)
            : state.companyDetails.companyAddresses
    };
    return updateObject(state, {
        loading: false,
        errorMessage: null,
        companyDetails: tempCompanyDetails,
        unUsedCompanyDetails: _.cloneDeep(tempCompanyDetails),
        unUsedUserProfile: _.cloneDeep(tempCompanyDetails.personDetail),
        unUsedComapnyAddresses: _.cloneDeep(tempCompanyDetails.companyAddresses),
        activeCampaigns: companyDetails.activeCampaigns,
        unUsedActiveCampaigns: _.cloneDeep(companyDetails.activeCampaigns),
        archivedCampaigns: companyDetails.archivedCampaigns,
        companyPackageDicounts: action.data.companyPackageDicounts,
        userName: action.data.companyDetails.personDetail.userName,
        businessEmail: action.data.companyDetails.personDetail.email,
        customerStatus: action.data.companyDetails.customerStatus,
        additionalEmail: action.data.companyDetails.additionalEmail,
        locations: createLocationObject(action.data.locations),
        unUsedLocations: createLocationObject(action.data.locations),
        packages: action.data.packages,
        isMovedToArchive: true
    });
};

const updateCompanyProfileSuccess = (state, action) => {
    const tempCompanyDetails = {
        companyId: action.data.companyObject.companyId,
        companyName: action.data.companyObject.companyName || '',
        companyLegalName: action.data.companyObject.companyLegalName,
        additionalEmail: action.data.companyObject.additionalEmail,
        adminNotes: action.data.companyObject.adminNotes,
        personDetail: action.data.companyObject.personDetailDTO,
        accountsPayableName: action.data.companyObject.accountsPayableName || '',
        accountsPayableEmail: action.data.companyObject.accountsPayableEmail || '',
        taxId: action.data.companyObject.taxId,
        customerSuccessPersonId: state.companyDetails.customerSuccessPersonId,
        timeZone: state.companyDetails.timeZone,
        companyAddresses: createCompanyaddressObject(
            action.data.companyObject.companyAddressPostDetailsDTO
        )
    };
    return updateObject(state, {
        isCompanyEditable: false,
        companyDetails: tempCompanyDetails,
        unUsedCompanyDetails: _.cloneDeep(tempCompanyDetails),
        unUsedUserProfile: _.cloneDeep(tempCompanyDetails.personDetail),
        unUsedComapnyAddresses: _.cloneDeep(tempCompanyDetails.companyAddresses)
    });
};

const saveLocationsSuccess = (state, action) => {
    return updateObject(state, {
        isLocationEditable: false,
        locations: createLocationObject(action.data),
        unUsedLocations: createLocationObject(action.data)
    });
};

const updateIsCompanyEditable = (state, action) => {
    return updateObject(state, {
        isCompanyEditable: action.data
    });
};

const updateIsLocationEditable = (state, action) => {
    return updateObject(state, {
        isLocationEditable: action.data
    });
};

const updateIsAccountSettingEditable = (state, action) => {
    return updateObject(state, {
        isAccountSettingsEditable: action.data
    });
};

const updateIsMovedToarchiveStatus = (state, action) => {
    return updateObject(state, {
        isMovedToArchive: action.data
    });
};

const moveToArchiveSuccess = (state, action) => {
    const tempActiveCampaigns = state.activeCampaigns;
    const tempArchivedCampaigns = state.archivedCampaigns;
    action.data.campaignIds.map((item) => {
        const index = tempActiveCampaigns.findIndex((x) => { return x.campaignId === item; });
        const res = tempActiveCampaigns.filter((x) => { return x.campaignId === item; });
        tempArchivedCampaigns.push(res[0]);
        tempActiveCampaigns.splice(index, 1);
    });
    return updateObject(state, {
        activeCampaigns: tempActiveCampaigns,
        unUsedActiveCampaigns: _.cloneDeep(tempActiveCampaigns),
        archivedCampaigns: tempArchivedCampaigns,
        isMovedToArchive: true
    });
};

const saveAccountSettingsSuccess = (state, action) => {
    const tempCompanyDetails = state.companyDetails;
    tempCompanyDetails.timeZone = action.data.companyObject.timeZone;
    tempCompanyDetails.customerSuccessPersonId = action.data.companyObject.customerSuccessPersonId;
    return updateObject(state, {
        companyDetails: tempCompanyDetails,
        unUsedCompanyDetails: _.cloneDeep(tempCompanyDetails),
        isAccountSettingsEditable: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.ADMIN_CUSTOMER_DETAILS_START:
        return getAdminCustomerDetailsStart(state, action);
    case actionTypes.ADMIN_CUSTOMER_DETAILS_FAIL:
        return getAdminCustomerDetailsFail(state, action);
    case actionTypes.GET_ADMIN_CUSTOMER_DETAILS_SUCCESS:
        return getAdminCustomerDetailsSuccess(state, action);
    case actionTypes.UPDATE_COMPANY_PROFILE_IS_SAVED:
        return updateCompanyProfileIsSaved(state, action);
    case actionTypes.ADMIN_CUSTOMER_DETAILS_SUCCESS:
        return adminCustomerDetailsFinish(state, action);
    case actionTypes.SAVE_ADMIN_LOCATION_SUCCESS:
        return saveLocationsSuccess(state, action);
    case actionTypes.UPDATE_IS_COMPANY_EDITABLE:
        return updateIsCompanyEditable(state, action);
    case actionTypes.UPDATE_IS_LOCATION_EDITABLE:
        return updateIsLocationEditable(state, action);
    case actionTypes.UPDATE_COMPANY_PROFILE_SUCCESS:
        return updateCompanyProfileSuccess(state, action);
    case actionTypes.MOVE_TO_ARCHIVE_SUCCESS:
        return moveToArchiveSuccess(state, action);
    case actionTypes.UPDATE_IS_MOVED_TO_ARCHIVE_STATUS:
        return updateIsMovedToarchiveStatus(state, action);
    case actionTypes.UPDATE_IS_ACCOUNT_SETTINGS_EDITABLE:
        return updateIsAccountSettingEditable(state, action);
    case actionTypes.SAVE_ACCOUNT_SETTINGS_SUCCESS:
        return saveAccountSettingsSuccess(state, action);
    case actionTypes.UPDATE_COMPANY_PROFILE_INITIAL_STATE:
        return initialState;
    default:
        return state;
    }
};

export default reducer;
